import { Link } from 'react-router-dom'
import 'styles/footer.css'
import logoGif from 'assets/logo.gif'

export default function Footer() {
  return (
    <div className="footer">
      <div className="small-600 footer-category-container">
        <img src={logoGif} className="logo-gif-footer" alt="logo" />
      </div>
      <div className="small-600 footer-category-container">
        Navigation
        <div className="footer-link-container">
          <Link className="footer-link" to={'about-us'}>
            About Us
          </Link>
          <Link className="footer-link" to={'faq'}>
            FAQ
          </Link>
          <Link className="footer-link" to={'contact'}>
            Contact
          </Link>
        </div>
      </div>
      <div className="small-600 footer-category-container">
        Contact
        <Link className="footer-link" to={'/host-application'}>
          Become a Host
        </Link>
        <a className="footer-link" href="mailto:support@badtrader.app">
          support@badtrader.app
        </a>
      </div>
      <div className="small-600 footer-category-container big-footer-container">
        Legal
        <a
          className="footer-link"
          href="http://www.badtrader.squarespace.com/s/Bad-Trader-Terms-of-Service-Dec-2024.docx"
        >
          Terms and Conditions
        </a>
        <a
          className="footer-link"
          href="https://static1.squarespace.com/static/6415e7b9d4a78e3637c4a203/t/647fcb0bd87bca53a3f0052a/1686096651474/Bad+Trader+Mobile+Terms+of+Service+Final-1.pdf"
        >
          Mobile Terms and Conditions
        </a>
        <a
          className="footer-link"
          href="https://static1.squarespace.com/static/6415e7b9d4a78e3637c4a203/t/647fc9ae6dafa05b409c9413/1686096303094/Bad+Trader+Community+Guidelines+Final-1.pdf"
        >
          Community Guidelines
        </a>
        {/* <Link className="footer-link" to={'/privacy-policy'}> */}
        <a
          className="footer-link"
          href="http://www.badtrader.squarespace.com/s/Bad-Trader-Privacy-Policy-SEPT-2024.docx"
        >
          Privacy Policy
        </a>
        {/* </Link> */}
        <a
          className="footer-link"
          href="https://static1.squarespace.com/static/6415e7b9d4a78e3637c4a203/t/647f3c1e6e9387243f4f0339/1686060062212/Bad+Trader+DMCA+Guidelines+Final.pdf"
        >
          DMCA Notifications Guidelines
        </a>
        <a
          className="footer-link"
          href="https://static1.squarespace.com/static/6415e7b9d4a78e3637c4a203/t/647f3c1e6e9387243f4f0339/1686060062212/Bad+Trader+DMCA+Guidelines+Final.pdf"
        >
          DMCA Notification Form
        </a>
        <a
          className="footer-link"
          href="https://static1.squarespace.com/static/6415e7b9d4a78e3637c4a203/t/647fc8f6a645fd1f1bf45429/1686096118308/Bad+Trader+Trademark+Guidelines+Final.pdf"
        >
          Trademark Usage Guidelines
        </a>
      </div>
    </div>
  )
}
