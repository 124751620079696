import 'styles/privacy-policy.css'
import 'styles/text.css'
import React from 'react'
import { Link } from 'react-router-dom'

export default function PrivacyPolicy() {
  React.useEffect(() => {
    window.location.replace('http://www.badtrader.squarespace.com/s/Bad-Trader-Privacy-Policy-SEPT-2024.docx')
  }, [])
  
  return (
    <>
      <div className="pp-page">
        {/* <div className="h1 pp-header">Bad Trader Privacy Policy.</div>
        <div className="h5 pp-date">Last Updated: 05/09/2023</div>
        <div className="h5 pp-reminder">
          Bad Trader, LLC (collectively “Bad Trader,” “we,” “us,” or “our”) is a media platform providing live and
          on-demand interactive video programming and chat forums about topics including but not limited to financial
          markets, trading, sports, entertainment, and other newsworthy subject matter. This Privacy Policy is designed
          to help you understand how we collect, use, process, and share your personal information, and to help you
          understand and exercise your privacy rights.
        </div>
        <div className="pp-row-container">
          <PolicyRow tag={'1'} text="1. SCOPE AND UPDATES TO THIS PRIVACY POLICY" />
          <PolicyRow tag={'2'} text="2. PERSONAL INFORMATION WE COLLECT" />
          <PolicyRow tag={'3'} text="3. HOW WE USE YOUR PERSONAL INFORMATION" />
          <PolicyRow tag={'4'} text="4. HOW WE DISCLOSE YOUR PERSONAL INFORMATION" />
          <PolicyRow tag={'5'} text="5. YOUR PRIVACY CHOICE AND RIGHTS" />
          <PolicyRow tag={'6'} text="6. SECURITY OF YOUR INFORMATION" />
          <PolicyRow tag={'7'} text="7. INTERNATIONAL DATA TRANSFERS" />
          <PolicyRow tag={'8'} text="8. RETENTION OF PERSONAL INFORMATION" />
          <PolicyRow
            tag={'9'}
            text="9. ADDITIONAL INFORMATION FOR RESIDENTS OF CALIFORNIA, VIRGINIA, CONNECTICUT, UTAH, AND COLORADO..."
          />
          <PolicyRow tag={'9A'} text="A. PERSONAL INFORMATION WE COLLECT AND SHARE" />
          <PolicyRow tag={'9B'} text="B. USE OF PERSONAL INFORMATION" />
          <PolicyRow tag={'9C'} text="C. SALE/SHARING OF PERSONAL INFORMATION" />
          <PolicyRow tag={'9D'} text="D. YOUR RIGHTS AND CHOICES" />
          <PolicyRow tag={'10'} text="10. SUPPLEMENTAL NOTICE FOR RESIDENTS OF NEVADA" />
          <PolicyRow tag={'11'} text="11. CHILDREN’S INFORMATION" />
          <PolicyRow tag={'12'} text="12. OTHER PROVISIONS" />
          <PolicyRow tag={'13'} text="13. CONTACT US" />
        </div>
        <div className="pp-content-container">
          <PolicyContainer
            id="1"
            title="1. SCOPE AND UPDATES TO THIS PRIVACY POLICY"
            content={
              <div className="large-600 pp-text">
                This Privacy Policy applies to personal information processed by Bad Trader, including on our websites,
                mobile applications, software, and other online or offline offerings. To make this Privacy Policy easier
                to read, our websites, platform, mobile applications, software, and other offerings are collectively
                referred to here as the “Services.”
                <br />
                <br />
                <span className="large-600 pp-text-highlight">Changes to our Privacy Policy.</span> We may revise this
                Privacy Policy from time to time in our sole discretion. If there are any material changes to this
                Privacy Policy, we will notify you as required by applicable law. You understand and agree that you will
                be deemed to have accepted the updated Privacy Policy if you continue to use our Services after the new
                Privacy Policy takes effect.
              </div>
            }
          />
          <PolicyContainer
            id="2"
            title="2. PERSONAL INFORMATION WE COLLECT"
            content={
              <div className="large-600 pp-text">
                The categories of personal information we collect depend on how you interact with us, our Services, and
                the requirements of applicable law. Generally speaking, we collect personal information that you provide
                to us, information we obtain automatically when you use our Services, and information from other sources
                such as third-party services and organizations. See below for more details regarding each category.
                <br />
                <br />
                <div className="pp-margin">
                  <span className="h5 pp-text-subheader">A. Personal Information You Provide to Us Directly</span>
                  <br />
                  <br />
                  We may collect personal information that you provide to us, such as:
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">Account Creation.</span> We may collect personal
                  information when you create an account, such as your email address, age, password, optional phone
                  number for multi-factor authentication, and the username you will present to other users.
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">Third-Party Services Information.</span> Our platform
                  may allow you to connect to an existing thirdparty account that you control, such as a brokerage
                  account, in order to access certain features. Although by default we do not store your third-party’s
                  login credentials or any banking/payment information, we do maintain metrics that track your
                  communications with that third party while using our platform.
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight"> Interactive Features.</span> We and others who use our
                  Services may collect personal information that you submit or make available through our interactive
                  features, including but not limited to realtime chat communications made in connection with our video
                  content. For example, our Services may include an “About Me” page, where you can choose to share your
                  milestones and progress with other users and/or select an avatar image. Any information you provide
                  using the public sharing features of the Services will be considered “public,” unless otherwise
                  required by applicable law, and is not subject to the privacy protections referenced herein. Please
                  exercise caution before revealing any information that may identify you in the real world to other
                  users.
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">Your Communications with Us.</span> We may collect
                  personal information, such as email address, phone number, or mailing address when you request
                  information about our Services, register for our newsletter or text message alerts, request customer
                  or technical support, or otherwise communicate with us. Surveys and Polls. We may contact you to
                  participate in surveys or polls. If you decide to participate, we may collect personal information
                  from you in connection with the survey or poll.
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">Promotions, Sweepstakes or Contests.</span> We may
                  collect personal information you provide for any promotions, sweepstakes, or contests that we offer.
                  In some jurisdictions, we are required to publicly share information of promotion, sweepstakes, and
                  contest winners.
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">Conferences, Trade Shows, and Other Events.</span> We
                  may collect personal information from individuals when we attend or host conferences, trade shows, and
                  other events.
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">
                    Business Development and Strategic Partnerships.
                  </span>{' '}
                  We may collect personal information from individuals and third parties to assess and pursue potential
                  business opportunities.
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">Job Applications.</span> We may post job openings and
                  opportunities on our Services. If you respond to one of these postings, we may collect your personal
                  information, such as your application, CV, cover letter, and/or any other information you provide to
                  us.
                  <br />
                  <br />
                  <span className="pp-text-subheader">B. Personal Information Collected Automatically</span>
                  <br />
                  <br />
                  <span className="pp-margin-0">
                    We may collect personal information that you provide to us, such as:
                  </span>
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">Automatic Collection of Personal Information.</span> We
                  may collect certain information automatically when you use our Services, such as your Internet
                  Protocol (IP) address, user settings, MAC address, cookie identifiers, mobile carrier, mobile
                  advertising and other unique identifiers, browser or device information, location information
                  (including approximate location derived from IP address), and Internet service provider. We may also
                  automatically collect information regarding your use of our Services, such as pages that you visit
                  before, during and after using our Services, information about the links you click, the types of
                  content you interact with, the frequency and duration of your activities, and other information about
                  how you use our Services.
                  <br />
                  <br />
                  <div className="pp-margin-2">
                    <span className="large-600 pp-text-highlight">Crash Reports.</span> If you provide crash reports, we
                    may collect personal information related to such crash reports, including detailed diagnostic
                    information about your device and the activities that led to the crash.
                  </div>
                  <br />
                  <span className="large-600 pp-text-highlight">
                    Cookies and Other Technologies (Cookie Policy).
                  </span>{' '}
                  We, as well as third parties that provide content, advertising, or other functionality on our
                  Services, may use cookies, pixel tags, and other technologies (collectively, “Technologies”) to
                  automatically collect information through your use of our Services.
                  <br />
                  <br />
                  <div className="pp-margin-2">
                    <span className="large-600 pp-text-highlight">Cookies.</span> Cookies are small text files placed in
                    device browsers that store preferences and facilitate and enhance your experience.
                    <br />
                    <br />
                    <span className="large-600 pp-text-highlight">Pixel Tags/Web Beacons.</span> A pixel tag (also known
                    as a web beacon) is a piece of code embedded in our Services that collects information about
                    engagement on our Services. The use of a pixel tag allows us to record, for example, that a user has
                    visited a particular web page or clicked on a particular advertisement. We may also include web
                    beacons in e-mails to understand whether messages have been opened, acted on, or forwarded.{' '}
                  </div>
                  <br />
                  <span className="pp-margin-0">
                    Our uses of these Technologies fall into the following general categories:
                  </span>
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">Operationally Necessary.</span> This includes
                  Technologies that allow you access to our Services, applications, and tools that are required to
                  identify irregular website behavior, prevent fraudulent activity, improve security, or allow you to
                  make use of our functionality;
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">Performance-Related.</span> We may use Technologies to
                  assess the performance of our Services, including as part of our analytic practices to help us
                  understand how individuals use our Services (see Analytics below);
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">Functionality-Related.</span> We may use Technologies
                  that allow us to offer you enhanced functionality when accessing or using our Services. This may
                  include identifying you when you sign into our Services or keeping track of your specified
                  preferences, interests, or past items viewed;
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">Advertising- or Targeting-Related.</span> We may use
                  first party or third-party Technologies to deliver content, including ads relevant to your interests,
                  on our Services or on third-party digital properties.
                  <br />
                  <br />
                  <div className="pp-margin-0">
                    Some of the advertising Technologies we use include, but are not limited to Meta, Google, AppLovin,
                    LiveRamp, Criteo, Microsoft, and their partners. Click each partner’s name for more information
                    regarding their privacy practices.
                    <br />
                    <br />
                    See <HashLink>“Your Privacy Choices and Rights”</HashLink> below to understand your choices
                    regarding these Technologies.
                  </div>
                  <br />
                  <span className="large-600 pp-text-highlight">Analytics.</span> We may use Technologies and other
                  third-party tools to process analytics information on our Services. These Technologies allow us to
                  better understand how our digital Services are used and to continually improve and personalize our
                  Services. Some of our analytics partners include:
                  <br />
                  <br />
                  <div className="pp-margin-2">
                    <span className="large-600 pp-text-highlight">Firebase/Google Analytics.</span> For more information
                    about how Google uses your personal information (including for its own purposes, e.g., for profiling
                    or linking it to other data), please visit Google Analytics’ Privacy Policy. To learn more about how
                    to opt-out of Google Analytics’ use of your information, please click here.
                  </div>
                  <br />
                  <span className="large-600 pp-text-highlight">Social Media Platforms.</span> Our Services may contain
                  social media buttons, such as Twitter, Instagram, TikTok, and YouTube, which might include widgets
                  such as the “share this” button or other interactive mini programs). These features may collect
                  personal information such as your IP address and which page you are visiting on our Services, and may
                  set a cookie to enable the feature to function properly. Your interactions with these platforms are
                  governed by the privacy policy of the company providing it.
                  <br />
                  <br />
                  <span className="pp-text-subheader">C. Personal Information Collected from Other Sources</span>
                  <br />
                  <br />
                </div>
                <span className="large-600 pp-text-highlight">Third-Party Services and Sources.</span> We may obtain
                personal information about you from other sources, including through third-party services and
                organizations. For example, if you access our Services through a third-party application, such as an app
                store, a third-party login service, or a social networking site, we may collect personal information
                about you from that third-party application that you have made available via your privacy settings.
                <br />
                <br />
                <span className="large-600 pp-text-highlight">Referrals and Sharing Features.</span> Our Services may
                offer various tools and functionalities that allow you to provide personal information about your
                friends through our referral service. Our referral services may also allow you to forward or share
                certain content with a friend or colleague, such as an email inviting your friend to use our Services.
                Please only share with us contact information of people with whom you have a relationship (e.g.,
                relative, friend, neighbor, or co-worker).
              </div>
            }
          />
          <PolicyContainer
            id="3"
            title="3. HOW WE USE YOUR PERSONAL INFORMATION"
            content={
              <div className="large-600 pp-text">
                We use your personal information for a variety of business purposes, including to provide our Services,
                for administrative purposes, and to market our products and Services, as described below.
                <br />
                <br />
                <div className="pp-margin">
                  <span className="pp-text-subheader">A. Provide Our Services</span>
                  <br />
                  <br />
                  We use your information to fullfil our contract with you and provide you with our Services, such as:
                </div>
                <br />
                • Managing your information and accounts;
                <br />
                • Providing access to certain areas, functionalities, and features of our Services;
                <br />
                • Answering requests for customer or technical support;
                <br />
                • Communicating with you about your account, activities on our Services, and policy
                <br />
                • Processing your financial information and other payment methods for products or Services purchased;
                <br />
                • Processing applications if you apply for a job we post on our Services;
                <br />
                • Allowing you to register for promotions and events.
                <br />
                <br />
                <span className="pp-text-subheader pp-margin">B. Administrative Purposes</span>
                <br />
                <br />
                We use your information for various administrative purposes, such as:
                <br />
                <br />
                <div className="pp-margin">
                  • Pursuing our legitimate interests such as direct marketing, research, and development (including
                  marketing research), network and information security, and fraud prevention;
                  <br />
                  • Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal
                  activity, and prosecuting those responsible for that activity;
                  <br />
                  • Measuring interest and engagement in our Services;
                  <br />
                  • Short-term, transient use, such as contextual customization of ads;
                  <br />
                  • Improving, upgrading, or enhancing our Services;
                  <br />
                  • Developing new products and services;
                  <br />
                  • Ensuring internal quality control and safety;
                  <br />
                  • Authenticating and verifying individual identities, including requests to exercise your rights under
                  this Privacy Policy;
                  <br />
                  • Debugging to identify and repair errors with our Services;
                  <br />
                  • Auditing relating to interactions, transactions, and other compliance activities;
                  <br />
                  • Sharing personal information with third parties as needed to provide the Services;
                  <br />
                  • Enforcing our agreements and policies; and
                  <br />
                  • Carrying out activities that are required to comply with our legal obligations.
                  <br />
                  <br />
                  <span className="pp-text-subheader">C. Marketing and Advertising our Products and Services</span>
                </div>
                <br />
                We may use personal information to tailor and provide you with content and advertisements. We may
                provide you with these materials as permitted by applicable law.
                <br />
                <br />
                Some of the ways we market to you include email campaigns, custom audiences advertising, and
                “interest-based” or “personalized advertising,” including through cross-device tracking.
                <br />
                <br />
                If you have any questions about our marketing practices or if you would like to opt out of the use of
                your personal information for marketing purposes, you may contact us at any time as set forth in “
                <HashLink>Contact Us</HashLink>” below.
                <br />
                <br />
                <span className="pp-text-subheader pp-margin">D. With Your Consent</span>
                <br />
                <br />
                We may use personal information for other purposes that are clearly disclosed to you at the time you
                provide personal information or with your consent.
                <br />
                <br />
                <span className="pp-text-subheader pp-margin">E. Other Purposes</span>
                <br />
                <br />
                We also use your personal information for other purposes as requested by you or as permitted by
                applicable law.
                <br />
                <br />
                <div className="pp-margin">
                  <span className="large-600 pp-text-highlight">Automated Decision Making.</span> We may engage in
                  automated decision making, including profiling. For example, we may engage in automated decision
                  making to recommend certain videos for you to watch on our Services. Bad Trader’s processing of your
                  personal information will not result in a decision based solely on automated processing that
                  significantly affects you unless such a decision is necessary as part of a contract we have with you,
                  we have your consent, or we are permitted by law to engage in such automated decision making. If you
                  have questions about our automated decision making, you may contact us as set forth in “Contact Us”
                  below.
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">De-identified and Aggregated Information.</span> We may
                  use personal information to create deidentified and/or aggregated information (for example, aggregate
                  reports detailing the total number of users we have in a given jurisdiction). We may share those
                  de-identified/aggregated records with third parties, including our advertising partners.
                </div>
              </div>
            }
          />
          <PolicyContainer
            id="4"
            title="4. HOW WE DISCLOSE YOUR PERSONAL INFORMATION"
            content={
              <div className="large-600 pp-text">
                We disclose your personal information to third parties for a variety of business purposes, including to
                provide our Services, to protect us or others, or in the event of a major business transaction such as a
                merger, sale, or asset transfer, as described below.
                <br />
                <br />
                <div className="pp-margin">
                  <span className="pp-text-subheader">A. Disclosures to Provide our Services</span>
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">Service Providers.</span> We may share your personal
                  information with our third-party service providers and vendors that assist us with the provision of
                  our Services. This includes but is not limited to service providers and vendors that provide us with
                  IT support, hosting, payment processing, customer service, and related services.
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">Business Partners.</span> We may share your personal
                  information with business partners to provide you with a product or service you have requested. We may
                  also share your personal information with business partners with whom we jointly offer products or
                  services.
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">Affiliates.</span> We may share your personal
                  information with our company affiliates (our “Affiliates”) for our administrative purposes, IT
                  management, or for them to provide services to you or support and supplement the Services we provide.
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">
                    Other Users or Third Parties You Share or Interact With.
                  </span>{' '}
                  As described above in “Personal Information We Collect,” our Services may allow you to share personal
                  information or interact with other users and third parties (including individuals and third parties
                  who do not use our Services and the general public).
                  <br />
                  <br />
                  <span className="large-600 pp-text-highlight">Advertising Partners.</span> We may share your personal
                  information with third-party advertising partners. These third-party advertising partners may set
                  Technologies and other tracking tools on our Services to collect information regarding your activities
                  and your device (e.g., your IP address, cookie identifiers, page(s) visited, location, time of day).
                  These advertising partners may use this information (and similar information collected from other
                  services) for purposes of delivering personalized advertisements to you when you visit digital
                  properties within their networks. This practice is commonly referred to as “interest-based
                  advertising” or “personalized advertising.”
                </div>
                <br />
                <span className="large-600 pp-text-highlight">APIs/SDKs.</span> We may use third-party application
                program interfaces (“APIs”) and software development kits (“SDKs”) as part of the functionality of our
                Services. For more information about our use of APIs and SDKs, please contact us as set forth in
                “Contact Us” below.
                <br />
                <br />
                <span className="pp-text-subheader">B. Disclosures to Protect Us or Others</span>
                <br />
                <br />
                <div className="pp-margin">
                  We may access, preserve, and disclose any information we store associated with you to external parties
                  if we, in good faith, believe doing so is required or appropriate to: comply with law enforcement or
                  national security requests and legal process, such as a court order or subpoena; protect your, our, or
                  others’ rights, property, or safety; enforce our policies or contracts; collect amounts owed to us; or
                  assist with an investigation or prosecution of suspected or actual illegal activity.
                </div>
                <br />
                <span className="pp-text-subheader">
                  C. Disclosure in the Event of Merger, Sale, or Other Asset Transfers
                </span>
                <br />
                <br />
                <div className="pp-margin">
                  If we are involved in an actual or proposed merger, acquisition, financing due diligence,
                  reorganization, bankruptcy, receivership, purchase, or sale of assets or all or parts of our business,
                  or transition of service to another provider, your information may be sold or transferred in
                  connection with such a transaction, as permitted by law and/or contract.
                </div>
              </div>
            }
          />
          <PolicyContainer
            id="5"
            title="5. YOUR PRIVACY CHOICES AND RIGHTS"
            content={
              <div className="large-600 pp-text">
                <span className="large-600 pp-text-highlight">Your Privacy Choices.</span> The privacy choices you may
                have about your personal information are determined by applicable law and are described below.
              </div>
            }
          />
        </div> */}
      </div>
    </>
  )
}

export function PolicyRow({ text, tag }) {
  return (
    <div className="h5 pp-row">
      <div className="pp-row-val">{text}</div>
      <div className="pp-line"></div>
      <Link to={`/privacy-policy#${tag}`}>
        <div className="large-600 pp-go-to">Go To</div>
      </Link>
    </div>
  )
}

export function PolicyContainer({ title, content, id }) {
  return (
    <div className="pp-container" id={id}>
      <div className="h4 pp-context-header">{title}</div>
      {content}
    </div>
  )
}
