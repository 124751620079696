import 'styles/error-page.css'
import 'styles/text.css'
import { ScrollRestoration, useRouteError } from 'react-router-dom'
import gif404 from 'assets/404.gif'
import Footer from 'components/footer'
import Header from 'components/header'

type ErrorResponse = {
  data: any
  status: number
  statusText: string
  message?: string
}

export default function ErrorPage() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const error = useRouteError() as ErrorResponse

  return (
    <>
      <Header useDark={true} />
      <div className="error-page">
        <div className="h1 error-header">
          {error.status} {error.statusText}.
        </div>
        <div className="h3 error-text">
          We don’t know how you got here but whatever you’re looking for isn’t here anymore.
        </div>
        <img src={gif404} className="error-gif" alt="arrows" />
      </div>
      <Footer />
      <ScrollRestoration />
    </>
  )
}
