import 'styles/landing.css'
import 'styles/text.css'
import social from 'assets/social.png'
import arrowRightActive from 'assets/arrowRightActive.png'
import invest from 'assets/invest.png'
import insight from 'assets/insight.png'
import simple from 'assets/simple.png'
import logoGif from 'assets/logo.gif'
import promo from 'assets/promo.png'
import mail from 'assets/mail.svg'
import Button from 'components/button'
import Background from 'components/background'
import bg from 'assets/landingBackground.jpeg'
import ScrollToAnchor from 'components/anchor'
// import WhatArePoints from 'assets/WhatArePoints.gif'

export default function Landing() {
  return (
    <>
      <ScrollToAnchor />
      <Background src={bg} tintColor={'#ff8f8f'} />
      <div className="header">
        <div className="header-top">
        <div className="h1 header-text">
         Meet
          <br />
         Bad Trader
        </div>
        </div>
        <div className="h3 header-text">
        An app with the tools, data, and community to help you learn more from your mistakes and become a more strategic investor. 
        </div>
      </div>
      <div className="content">
        <div className="content-container margin-content">
          <div className="small-content-container">
            <div className="h2 highlight-purple">
              Social Investing
            </div>
            <div className="info-container-left">
              <div className="h3 info-text">
                Watch daily streams that feature live trading and market coverage, and talk trading and news in the Bad Trader forums.
              </div>
              <br />
              <div className="h5 info-text itemRow">
              <img src={arrowRightActive} className="arrowRight" alt="arrowActive" />
              Daily livestreams
              </div>
              <div className="h5 info-text itemRow">
              <img src={arrowRightActive} className="arrowRight" alt="arrowActive" />
              Live chat
              </div>
              <div className="h5 info-text itemRow">
              <img src={arrowRightActive} className="arrowRight" alt="arrowActive" />
              Community forums
              </div>
            </div>
          </div>
          <div className="photo-container">
            <img src={social} className="photo" alt="social" />
          </div>
        </div>
        <div className="content-container margin-content content-container-reverse">
        <div className="photo-container">
            <img src={invest} className="photo" alt="invest" />
          </div>
        <div className="small-content-container">
            <div className="h2 highlight-green">
              Strategic Investing
            </div>
            <div className="info-container-right">
              <div className="h3 info-text">
              Track analyst ratings and price targets, track market events like earnings and CPI, and filter for symbols using a detailed stock screener.
            </div>
              <br />
              <div className="h5 info-text itemRow">
              <img src={arrowRightActive} className="arrowRight" alt="arrowActive" />
              Analyst ratings
              </div>
              <div className="h5 info-text itemRow">
              <img src={arrowRightActive} className="arrowRight" alt="arrowActive" />
              Market events calendar
              </div>
              <div className="h5 info-text itemRow">
              <img src={arrowRightActive} className="arrowRight" alt="arrowActive" />
              Stock screener
              </div>
            </div>
          </div>
        </div>
        <div className="content-container margin-content">
        <div className="small-content-container">
            <div className="h2 highlight-red">
              Simplified Investing
            </div>
            <div className="info-container-left">
              <div className="h3 info-text">
              Get visual snapshots of daily market data, set up orders with our simplified  UI, and view payout diagrams for option orders. 
            </div>
              <br />
              <div className="h5 info-text itemRow">
              <img src={arrowRightActive} className="arrowRight" alt="arrowActive" />
              Market snapshots and visuals
              </div>
              <div className="h5 info-text itemRow">
              <img src={arrowRightActive} className="arrowRight" alt="arrowActive" />
              Simplified option chain
              </div>
              <div className="h5 info-text itemRow">
              <img src={arrowRightActive} className="arrowRight" alt="arrowActive" />
              Stock screener
              </div>
            </div>
          </div>
          <div className="photo-container">
            <img src={simple} className="photo" alt="simple" />
          </div>
        </div>
        <div className="content-container margin-content content-container-reverse">
        <div className="photo-container">
            <img src={insight} className="photo" alt="insight" />
          </div>
        <div className="small-content-container">
            <div className="h2 highlight-yellow">
              Insightful Investing
            </div>
                                {/* <img src={WhatArePoints} className="ufo" alt="chaseGus" /> */}
            <div className="info-container-right">
              <div className="h3 info-text">
              Connect your brokerage account to gain portfolio insights and make more informed decisions. 
            </div>
              <br />
              <div className="h5 info-text itemRow">
              <img src={arrowRightActive} className="arrowRight" alt="arrowActive" />
              Portfolio history
              </div>
              <div className="h5 info-text itemRow">
              <img src={arrowRightActive} className="arrowRight" alt="arrowActive" />
              Sector breakdown
              </div>
              <div className="h5 info-text itemRow" id="download-1">
              <img src={arrowRightActive} className="arrowRight" alt="arrowActive" />
              YTD summary
              </div>
            </div>
          </div>
        </div>
        <div className="promo-background">
          <div className="promo-container">
            <div className="column-promo">
              <img src={logoGif} className="gif-logo-home" alt="logo" />
              <img src={promo} className="promo" alt="promo" />
            </div>
            <div className="promo-content-container">
              <div className="h3 promo-header">Want to join our legion of Bad Traders?</div>
              <div className="p1 promo-subline">Download the latest and greatest for your mobile device below.</div>
              <div className="button-container-promo">
                <a
                  href="https://play.google.com/store/apps/details?id=com.tastyworks.mobile.badtrader.android&hl=en_US&gl=US"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    buttonStyle="rounded-button-light rounded-button-label-light button-left"
                    aria-label={'Android'}
                    label="Android"
                  />
                </a>
                <a
                  href="https://apps.apple.com/us/app/bad-trader/id1638952698"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    buttonStyle="rounded-button-light rounded-button-label-light"
                    aria-label={'iOS'}
                    label="iOS"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mailing-container">
          <div className="mailing-inner-container">
          <div className="h2 mailing-header">
            Want to join our sexy
            <br /> mailing list?
          </div>
          <div className="mailing-bottom-container">
            <div className="mailing-form">
              <div className="mailing-text">
                We'll send you app updates. Just drop your email here to keep in touch.
              </div>
              <iframe
                frameBorder="0"
                scrolling="no"
                title="form-email-list"
                className="mailing-form-iframe"
                src="https://cdn.forms-content-1.sg-form.com/c96d6330-517d-11ee-9f93-ea8b1437f8af"
              />
            </div>
            <div className="mail-photo-container">
              <img src={mail} className="mail" alt="chaseGus" />
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  )
}
